import React, { FC } from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType, StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import { useTranslation } from "react-i18next";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter(
  (_, i) => i < 8
);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t } = useTranslation("experiences");

  // Detección de pantalla grande
  const isLargeScreen = window.innerWidth >= 1280; // Pantalla grande a partir de 1280px

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        onToggleMap={isLargeScreen ? onToggleMap : undefined} // Solo pasar la función si es pantalla grande
        isMapVisible={isMapVisible}
        heading={t("experiencesIn", "Experiences in Tokyo")}
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {t("numberOfExperiences", { count: 233 })}
            <span className="mx-2">·</span>
            Aug 12 - 18
            <span className="mx-2">·</span>
            {t("numberOfGuests", { count: 2 })}
          </span>
        }
      />

      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>
      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
        {data.map((stay) => (
          <ExperiencesCard key={stay.id} data={stay} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
