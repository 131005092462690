import { FC, useState } from "react";
import StayCardH from "components/StayCardH/StayCardH";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import MapContainer from "containers/MapContainer";

const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

export interface SectionGridHasMapProps {
  isMapVisible: boolean;
  onToggleMap: () => void;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  isMapVisible,
  onToggleMap,
}) => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);

  return (
    <div className="relative flex min-h-screen">
      {/* Contenedor de las tarjetas */}
      <div className="w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 relative z-20">
        <Heading2
          onToggleMap={onToggleMap}
          isMapVisible={isMapVisible}
          heading="Alojamientos en Tokyo"
        />
        <div className="mb-8 lg:mb-11">
          <TabFilters />
        </div>
        <div className="grid grid-cols-1 gap-8">
          {DEMO_STAYS.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => setCurrentHoverID(item.id)}
              onMouseLeave={() => setCurrentHoverID(-1)}
            >
              <StayCardH data={item} />
            </div>
          ))}
        </div>
        <div className="flex mt-16 justify-center items-center">
          <Pagination />
        </div>
      </div>

      {/* Contenedor del Mapa */}
      {isMapVisible && (
        <div className="flex-grow xl:ml-8 h-full xl:h-[calc(100vh-88px)] sticky top-0">
          <div className="w-full h-full rounded-md overflow-hidden">
            <MapContainer
              currentHoverID={currentHoverID}
              DEMO_DATA={DEMO_STAYS}
              listingType="stay"
            />
            <ButtonClose
              onClick={onToggleMap}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionGridHasMap;
