import React from "react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { FaMap } from "react-icons/fa";

export interface Heading2Props {
  heading?: ReactNode;
  subHeading?: ReactNode;
  className?: string;
  onToggleMap?: () => void;
  isMapVisible?: boolean;
}

const Heading2: React.FC<Heading2Props> = ({
  className = "",
  heading,
  subHeading,
  onToggleMap,
  isMapVisible = false,
}) => {
  const { t } = useTranslation("heading2");

  return (
    <div className={`mb-12 lg:mb-16 ${className} mt-8 lg:mt-12`}>
      <div className="flex items-center">
        <h2 className="text-4xl font-semibold">
          {heading || t("staysIn")}
        </h2>
        {onToggleMap && (
          <div className="ml-4">
            <ButtonPrimary
              onClick={onToggleMap}
              className={`flex items-center px-3 py-1 rounded-md text-sm ${
                isMapVisible ? "bg-red-500 hover:bg-red-600" : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              <FaMap size={16} />
            </ButtonPrimary>
          </div>
        )}
      </div>
      {subHeading ? (
        subHeading
      ) : (
        <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
          {t("numberOfStays")}
          <span className="mx-2">·</span>
          {t("dates")}
          <span className="mx-2">·</span>{t("guests")}
        </span>
      )}
    </div>
  );
};

export default Heading2;
