import React, { FC, useEffect, useRef, useState, startTransition } from "react";
import Logo from "shared/Logo/Logo";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import HeroSearchFormSmall, { SearchTab } from "components/HeroSearchFormSmall/HeroSearchFormSmall";
import { Link, useLocation } from "react-router-dom";
import NotifyDropdown from "./NotifyDropdown";
import AvatarDropdown from "./AvatarDropdown";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { StaySearchFormFields } from "components/HeroSearchForm/type";
import { useAuth } from "contexts/AuthContext";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useTranslation } from "react-i18next";
import LangDropdown from "./LangDropdown";
import CurrencyDropdown from "./CurrencyDropdown";

interface Header3Props {
  className?: string;
}

let WIN_PREV_POSITION = window.pageYOffset;

const Header3: FC<Header3Props> = ({ className = "" }) => {
  const headerInnerRef = useRef<HTMLDivElement>(null);
  const searchContainerRef = useRef<HTMLDivElement>(null);
  const { isAuthenticated } = useAuth();

  const [showHeroSearch, setShowHeroSearch] = useState<StaySearchFormFields | null>(null);
  const [currentTab, setCurrentTab] = useState<SearchTab>("Stays");
  const [searchContainerPosition, setSearchContainerPosition] = useState<number | null>(null);
  const [showText, setShowText] = useState(true);
  const [searchValue, setSearchValue] = useState(""); // Estado para guardar el valor de búsqueda

  const location = useLocation();
  const { t } = useTranslation("header3");

  const isHomePage = location.pathname === "/" || location.pathname === "/login";

  useOutsideAlerter(headerInnerRef, () => {
    startTransition(() => {
      setShowHeroSearch(null);
      setCurrentTab("Stays");
      setShowText(true);
    });
  });

  useEffect(() => {
    startTransition(() => {
      setShowHeroSearch(null);
    });
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleEvent);
    return () => {
      window.removeEventListener("scroll", handleEvent);
    };
  }, []);

  const handleEvent = () => {
    window.requestAnimationFrame(handleHideSearchForm);
  };

  const handleHideSearchForm = () => {
    let currentScrollPos = window.pageYOffset;
    if (Math.abs(WIN_PREV_POSITION - currentScrollPos) > 100) {
      startTransition(() => {
        setShowHeroSearch(null);
      });
    }
    WIN_PREV_POSITION = currentScrollPos;
  };

  const handleOpenSearch = (field: StaySearchFormFields) => {
    if (searchContainerRef.current) {
      const rect = searchContainerRef.current.getBoundingClientRect();
      setSearchContainerPosition(rect.left + rect.width / 2);
    }
    startTransition(() => {
      setShowHeroSearch(field);
      setShowText(false);
    });
  };

  const renderHeroSearch = () => (
    <div
      className={`absolute inset-x-0 top-0 transition-all will-change-[transform,opacity] ${
        showHeroSearch ? "visible opacity-100" : "-translate-y-10 opacity-0 invisible"
      }`}
      style={{
        left: "50%",
        transform: "translateX(-50%)",
        maxWidth: "800px",
      }}
    >
      <div className="w-full max-w-4xl mx-auto pb-6 px-4">
        <HeroSearchFormSmall
          defaultFieldFocus={showHeroSearch || undefined}
          onTabChange={setCurrentTab}
          defaultTab={currentTab}
          searchValue={searchValue}
          onSearchChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
    </div>
  );

  const renderButtonOpenHeroSearch = () => (
    <div
      ref={searchContainerRef}
      className={`max-w-md w-full flex items-center justify-between border border-neutral-200 rounded-full shadow hover:shadow-md transition-all px-3 ${
        showHeroSearch ? "opacity-0 pointer-events-none" : "opacity-100"
      }`}
      onClick={() => handleOpenSearch("location")}
    >
      <div className="flex items-center font-medium text-sm space-x-2">
        {showText ? (
          <>
            <span className="block pl-2 pr-2 cursor-pointer py-2">{t("location")}</span>
            <span className="h-4 w-[1px] bg-neutral-300"></span>
            <span className="block px-2 cursor-pointer py-2">{t("checkIn")}</span>
            <span className="h-4 w-[1px] bg-neutral-300"></span>
            <span className="block px-2 cursor-pointer py-2">{t("addGuests")}</span>
          </>
        ) : (
          <span className="block cursor-pointer py-2">
            <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24">
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
              ></path>
            </svg>
          </span>
        )}
      </div>

      <div className="flex-shrink-0 ml-auto pr-1">
        <span className="w-7 h-7 flex items-center justify-center rounded-full bg-primary-6000 text-white">
          <svg className="w-4 h-4" fill="none" viewBox="0 0 24 24">
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M19.25 19.25L15.5 15.5M4.75 11C4.75 7.54822 7.54822 4.75 11 4.75C14.4518 4.75 17.25 7.54822 17.25 11C17.25 14.4518 14.4518 17.25 11 17.25C7.54822 17.25 4.75 14.4518 4.75 11Z"
            ></path>
          </svg>
        </span>
      </div>
    </div>
  );

  return (
    <>
      <div
        className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 transition-opacity will-change-[opacity] ${
          showHeroSearch ? "visible" : "invisible opacity-0 pointer-events-none"
        }`}
      ></div>
      {showHeroSearch && <div id="nc-Header-3-anchor"></div>}
      <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
        <div
          className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity] ${
            showHeroSearch ? "duration-75" : ""
          }`}
        ></div>

        {/* Contenedores para el Header */}
        <div className="relative px-4 lg:container flex items-center pt-2 pb-4"> {/* Ajustar margen superior para centrar la barra */}
          {/* Logo */}
          <div className={`flex-shrink-0 hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <Logo />
          </div>

          {/* Barra de búsqueda para pantallas grandes */}
          <div className={`flex-grow max-w-lg hidden lg:flex justify-center ${showHeroSearch ? "invisible" : "visible"}`}>
            {!isHomePage && (
              <div className="hidden lg:block">
                {renderButtonOpenHeroSearch()}
              </div>
            )}
            {renderHeroSearch()}
          </div>

          {/* Idioma y Moneda */}
          <div className={`flex-shrink-0 flex space-x-2 items-center hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <LangDropdown />
            <CurrencyDropdown />
          </div>

          {/* Publica tu propiedad y sesión */}
          <div className={`flex-shrink-0 flex items-center space-x-4 hidden lg:flex ${showHeroSearch ? "invisible" : "visible"}`}>
            <Link
              to="/add-listing-1"
              className="hidden xl:inline-flex text-opacity-90 group px-4 py-2 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none"
              style={{ whiteSpace: "nowrap" }}
            >
              {t("listYourProperty")}
            </Link>
            {isAuthenticated && (
              <div className="pr-1.5">
                <NotifyDropdown className="-ml-2 xl:-ml-1" />
              </div>
            )}
            {isAuthenticated ? (
              <AvatarDropdown />
            ) : (
              <ButtonPrimary
                href="/login"
                className="px-4 py-2 text-sm text-white bg-primary-6000 hover:bg-primary-700 rounded-full focus:outline-none min-w-[100px]"
              >
                {t("logIn")}
              </ButtonPrimary>
            )}
          </div>
        </div>

        {/* Barra de búsqueda para móviles */}
        <div className="lg:hidden w-full max-w-lg mx-auto mt-0">
          <HeroSearchForm2MobileFactory />
        </div>
      </header>
    </>
  );
};

export default Header3;
