import React, { FC, useState } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridHasMap from "./SectionGridHasMap";
import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export interface ListingExperiencesMapPageProps {
  className?: string;
}

const ListingExperiencesMapPage: FC<ListingExperiencesMapPageProps> = ({
  className = "",
}) => {
  const { t } = useTranslation("experiences");
  const [isMapVisible, setIsMapVisible] = useState(false);

  const handleToggleMap = () => {
    setIsMapVisible(!isMapVisible);
  };

  return (
    <div
      className={`nc-ListingExperiencesMapPage relative ${className}`}
      data-nc-id="ListingExperiencesMapPage"
    >
      <Helmet>
        <title>
          Bedlia - Experiencias, Actividades y mucho más...
        </title>
      </Helmet>
      <BgGlassmorphism />

      {/* Alternar entre la vista del mapa y los filtros */}
      <div
        className={`${
          isMapVisible ? "w-full h-full" : "container max-w-6xl mx-auto"
        } pb-24 lg:pb-28 2xl:pl-10 xl:pr-0`}
      >
        {isMapVisible ? (
          <SectionGridHasMap
            isMapVisible={isMapVisible}
            onToggleMap={handleToggleMap}
          />
        ) : (
          <SectionGridFilterCard
            className="pb-24 lg:pb-28"
            onToggleMap={handleToggleMap}
            isMapVisible={isMapVisible}
          />
        )}
      </div>
    </div>
  );
};

export default ListingExperiencesMapPage;
