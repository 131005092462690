import React, { FC } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { StaySearchFormFields } from "components/HeroSearchForm/type";

export interface StaySearchFormProps {
  defaultFieldFocus?: StaySearchFormFields;
  searchValue?: string; // Nueva propiedad para el valor de búsqueda
  onSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // Nueva propiedad para manejar el cambio en el input
}

const StaySearchForm: FC<StaySearchFormProps> = ({ defaultFieldFocus, searchValue, onSearchChange }) => {
  const renderForm = () => {
    return (
      <form className="relative flex rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
        <LocationInput
          className="flex-[1.5]"
          autoFocus={defaultFieldFocus === "location"}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput className="flex-[1.2]" />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-1"
          autoFocus={defaultFieldFocus === "guests"}
          submitLink="/search"
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
